import axios from 'axios';
import React, { useState } from 'react';
import { EditClaim } from '../../../interface/ClaimsInterface';
import { endpoints } from '../../../utils/URL';
import { isFormValidated } from '../../../utils/formUtils';
import { addToast } from '../../../utils/toastNotifications';
import { Modal } from 'react-bootstrap';
import ButtonLoader from '../../common/ButtonLoader';

import { FulfilmentStatusMap } from '../../../utils/mappings';
import { formatInputDate } from '../../../utils/dateTimeUtils';

const EditClaimModal = ({ open, close, data, handleChange, loadClaims }: EditClaim) => {
  const [isLoading, setLoading] = useState(false);

  const updateClaim = async () => {
    if (!isFormValidated('edit-claims-modal') || data.pharmacyId < 1 || data.nps < 1) {
      addToast('Please fill all required fields', 'warning');
      return;
    }

    const payload = {
      insuranceClaimId: data.insuranceClaimId,
      subscriptionCode: data.subscriptionCode,
      beneficiaryId: data.beneficiaryId,
      claimDate: data.claimDate,
      notes: data.notes,
      nps: data.nps,
      pharmacyName: data.pharmacyName,
      pharmacyCode: data.pharmacyCode,
      claimStatus: data.claimStatus,
      creationMode: data.creationMode,
      claimProvider: data.claimProvider,
    };
    setLoading(true);

    const url = `${endpoints.InsuranceClaims.mainUrl}`;
    try {
      await axios.put(url, payload);
      addToast('Claim successfully updated', 'success');
      close();
      loadClaims();
      // }
    } catch (error: any) {
      if (error && error.response.status === 422) {
        addToast(
          'An error occured when saving the claim. Check that subscription is valid and Active',
          'error'
        );
      } else {
        addToast('An error occured when saving the claim', 'error');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={open} onHide={close}>
      <form id='edit-claims-modal'>
        <Modal.Header closeButton>
          <Modal.Title>{data.beneficiaryName}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-body'>
          <fieldset disabled={isLoading}>
            <div className='col-md-12'>
              <label className='form-label'>Subscription Code</label>
              <input value={data.subscriptionCode} required disabled className='form-control' />
            </div>
            <br />

            <div className='col-md-12'>
              <label className='form-label'>Date of Claim</label>
              <input
                value={formatInputDate(data.claimDate)}
                className='form-control'
                type='date'
                onChange={handleChange('claimDate')}
              />
            </div>
            <br />

            <div className='col-md-12'>
              <label className='form-label'>Claim Status</label>
              <select
                required
                onChange={handleChange('claimStatus')}
                value={data.claimStatus}
                className='custom-select'
              >
                {FulfilmentStatusMap.map((option) => (
                  <option key={option.key} value={option.value}>
                    {option.text}
                  </option>
                ))}
              </select>
            </div>
            <br />

            <div className='col-md-12'>
              <label className='form-label'>Pharmacy Name</label>
              <input value={data.pharmacyName} disabled className='form-control' />
            </div>
            <br />
            <div className='col-md-12'>
              <label className='form-label'>Total Claim Amount (₦)</label>
              <input
                value={data.claimAmount}
                min='0'
                type='number'
                disabled
                className='form-control'
              />
            </div>
            <br />

            <div className='col-md-12'>
              <label className='form-label'>NPS (0 - 10)</label>
              <input
                value={data.nps}
                min='0'
                max='10'
                required
                type='number'
                onChange={handleChange('nps')}
                className='form-control'
              />
            </div>
            <br />
            <div className='col-md-12'>
              <label className='form-label'>Notes</label>
              <textarea
                value={data.notes}
                onChange={handleChange('notes')}
                className='form-control'
              />
            </div>
            <br />
          </fieldset>
        </Modal.Body>
        <Modal.Footer>
          <button
            type='button'
            className='btn btn-primary'
            onClick={updateClaim}
            disabled={isLoading}
          >
            UPDATE
            {isLoading ? <ButtonLoader /> : ''}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default EditClaimModal;
